import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useTheme } from "@material-ui/core";
import { ExperimentState, PackagesModuleProps } from "redmond";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";

import CapOneLogo from "../../assets/b2b/capone-logo.svg";
import { PAWTUCKET_PACKAGES_MODULE_ID } from "../../utils/moduleIds";
import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { colors } from "../../utils/capone/colors";
import clsx from "clsx";
import { useUserContext } from "@capone/common";
import { CAP_ONE_LOGO_ALT } from "../../utils/constants";
import {
  useExperiments,
  PACKAGES_EXPERIMENT,
  PACKAGES_EXPERIMENT_VARIANTS,
  getExperimentVariantCustomVariants,
  SELF_FUNDED,
} from "../../context/experiments";
import { PATH_HOTELS } from "../../utils/urlPaths";
import "./styles.scss";

interface IPackagesModuleProps extends RouteComponentProps {
  language: string;
  experiments?: ExperimentState;
  recentlyViewedLoaded: boolean;
}

const PackagesModule = (props: IPackagesModuleProps) => {
  const { language, experiments, recentlyViewedLoaded, ...routerProps } = props;
  const { history } = routerProps;
  const id = PAWTUCKET_PACKAGES_MODULE_ID;
  const theme = useTheme();
  const { matchesDesktop, matchesSmallDesktop } = useDeviceTypes();
  const { isLoaded, ptPackagesModule } = useMicrofrontend(
    id,
    (config.ptPackagesModuleUrl += "")
  );
  const { sessionInfo } = useUserContext(config.TENANT);

  const expState = useExperiments();

  const packagesExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    PACKAGES_EXPERIMENT,
    PACKAGES_EXPERIMENT_VARIANTS
  );

  const isPackagesExperiment = packagesExperimentVariant.includes(SELF_FUNDED); // show funnel if variant === `self-funded` or `not-self-funded`

  useEffect(() => {
    if (experiments?.experiments.length && !isPackagesExperiment) {
      history.push(PATH_HOTELS);
    }
  }, [experiments]);

  const isRecentlyViewed: boolean = useMemo(
    () => recentlyViewedLoaded,
    [recentlyViewedLoaded]
  );

  useLayoutEffect(() => {
    if (!ptPackagesModule) return;
    const { render, unMount } = ptPackagesModule;
    const PackagesModuleProps: PackagesModuleProps = {
      theme,
      language: language,
      colors: colors,
      baseHistory: history,
      isAgentPortal: config.ptIsAgentPortal,
      clientAssets: {
        logo: <img src={CapOneLogo} alt={CAP_ONE_LOGO_ALT} />,
        sessionInfo,
      },
      experiments,
    };

    render(id, PackagesModuleProps);

    return () => unMount(id);
  }, [isLoaded]); // eslint-disable-line

  if (!isLoaded) return null;

  if (!ptPackagesModule) return <h2>ERROR! Cannot find Module</h2>;

  return (
    <div
      className={clsx("packages-module-container", {
        desktop: matchesDesktop || matchesSmallDesktop,
        "recently-viewed": isRecentlyViewed,
      })}
      id={id}
    />
  );
};

export default PackagesModule;
