import axios from "axios";
import { RewardsAccountsResponse } from "redmond";
import { portalAccessPrefix } from "../../paths";

const ENDPOINT = `${portalAccessPrefix}/businessAuthorized`;

const fetchBusinessPortalAuthorized =
  async (): Promise<RewardsAccountsResponse> => {
    try {
      const resp = await axios.put(
        ENDPOINT,
        {},
        {
          withCredentials: true,
        }
      );
      return resp.data;
    } catch (e) {
      return [];
    }
  };

export default fetchBusinessPortalAuthorized;
